import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {observer} from "mobx-react";
import {Button, Layout as LayoutAntd, Typography} from "@bezlimit/bezlimit-ui";
import {isSafari, isAndroid, isMobile} from "react-device-detect";
import _ from "lodash";
import "./style.scss";
// layouts
import PrivateLayout from "./private";
import PublicLayout from "./public";
// routes
import privateRoutes, {PrivateRouteTypes} from "./routes/privateRoutes";
import publicRoutes, {PublicRouteTypes} from "./routes/publicRoutes";
// components
import Header from "../components/blocks/header";
import Footer from "../components/blocks/footer";
import Sider from "../components/blocks/sider";
// pages
import NotFound from "../components/public/pages/notFound";
// hooks
import useSearchRoute from "../core/hooks/useSearchRoute";
import useInitUser from "../core/hooks/useInitUser";
// services
import UserService from "../services/UserService";
import {useDocumentTitle} from "../core/hooks/useDocumentTitle";
import Loading from "../components/blocks/ui/loading";
import SiderProfile from "../components/blocks/profile/sider";
import {SessionContext} from "../core/Contexts";
// store
import Store from "../stores";

const {Content} = LayoutAntd;

export default React.memo(
    observer(function Layout() {
        const [pathname] = useState([
            "/reservation",
            "/activation",
            "/bonus",
            "/deliveries",
            "/deliveries/archive",
            "/deliveries/new",
            "/gift",
            "/numbers"
        ]);
        const [loading, setLoading] = useState(true);
        const [collapsed, setCollapsed] = useState(true);
        const [collapsed2, setCollapsed2] = useState(true);
        const route = useSearchRoute(UserService.token);
        const location = useLocation();
        useInitUser();

        const toggle = () => {
            setCollapsed(!collapsed);
        };

        const toggleCollapsed = () => {
            setCollapsed2(!collapsed2);
        };

        const documentTitle = `${_.get(route, "title")} | Store`;
        useDocumentTitle(documentTitle);

        useEffect(() => {
            if (_.isEqual(process.env.NODE_ENV, "production")) {
                (window as any).ym(90016982, "hit", window.location.href);

                const gtag = (window as any).gtag;
                if (typeof gtag === "function") {
                    gtag("config", "G-BGFRMS4YK1", {
                        page_title: document.title,
                        page_location: window.location.href,
                        page_path: location.pathname
                    });
                }
            }
        }, [location.pathname]);

        useEffect(() => {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }, []);

        return (
            <LayoutAntd>
                {!!loading ? (
                    <Loading />
                ) : (
                    <>
                        <SessionContext.Provider value={{toggleCollapsed}}>
                            {UserService.token && <Sider collapsed={collapsed} toggle={toggle} />}
                            {UserService.token && (
                                <SiderProfile collapsed={collapsed2} toggle={toggleCollapsed} />
                            )}
                            <LayoutAntd className="site-layout">
                                {UserService.token &&
                                pathname.includes(location.pathname) &&
                                isMobile ? (
                                    <Button className="paralax-btn" onClick={toggle} />
                                ) : UserService.token ? (
                                    <Header toggle={toggle} />
                                ) : null}
                                <Content
                                    className={`page ${isSafari ? "is-safari" : ""} ${
                                        isAndroid ? "is-android" : ""
                                    } ${_.get(route, "class")} ${
                                        !_.isNil(Store.user.loyalty) ? "" : "page-without-loyalty"
                                    }`}
                                    style={{
                                        minHeight: "100vh"
                                    }}
                                >
                                    <Typography as="div" className="bl-content">
                                        <Routes>
                                            {!UserService.token
                                                ? _.map<typeof publicRoutes>(
                                                      publicRoutes,
                                                      (route: PublicRouteTypes, key: string) => {
                                                          const {component, path} = route;
                                                          return (
                                                              <Route
                                                                  path={path}
                                                                  key={key}
                                                                  element={
                                                                      <PublicLayout
                                                                          component={component}
                                                                      />
                                                                  }
                                                              />
                                                          );
                                                      }
                                                  )
                                                : _.map<typeof privateRoutes>(
                                                      privateRoutes,
                                                      (route: PrivateRouteTypes, key: string) => {
                                                          const {component, path} = route;
                                                          return (
                                                              <Route
                                                                  path={path}
                                                                  key={key}
                                                                  element={
                                                                      <PrivateLayout
                                                                          component={component}
                                                                      />
                                                                  }
                                                              />
                                                          );
                                                      }
                                                  )}

                                            <Route path="*" element={<NotFound />} />
                                        </Routes>
                                    </Typography>

                                    {UserService.token && <Footer />}
                                </Content>
                            </LayoutAntd>
                        </SessionContext.Provider>
                    </>
                )}
            </LayoutAntd>
        );
    })
);
